import React from "react"
import { Alert } from "reactstrap"
import { CleanHtml } from "swiipe.portal.shared"
import { getDateToLocalStringWithOptions } from "../../services/dateTimeService"
import { IClearhausComment } from "../../type/clearhaus/IClearhausComment"
import { convertLineBreaksToHTML } from "../../util/htmlUtil"
import "./ClearhausComment.scss"

interface IClearhausCommentProps {
    comment: IClearhausComment
}

export const ClearhausComment = ({ comment }: IClearhausCommentProps) => {
    return (
        <Alert isOpen={true} className="clearhaus-single-comment" color="dark" key={comment.createdAt}>
            <CleanHtml element="div" className="clearhaus-single-comment__text" html={convertLineBreaksToHTML(comment.body)} />
            <div className="clearhaus-single-comment__date">
                {getDateToLocalStringWithOptions(comment.createdAt, {
                    dateStyle: "medium",
                    timeStyle: "short",
                })}
            </div>
        </Alert>
    )
}
