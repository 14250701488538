import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form, Input } from "reactstrap"
import {
    CleanHtml,
    FieldsetFormGroup,
    sharedConfigurationSelectors,
    ShowErrorMessages,
    useSelector,
    valFuncRequiredAndPattern,
    validationPatterns,
} from "swiipe.portal.shared"
import { getImage } from "../../services/imageLanguageService"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { anydayOnboardingConnectThunk } from "../../store/thunks/anydayThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import SubmitButton from "../buttons/SubmitButton"
import "./OnboardAnydayConnect.scss"
import ServicesOnboardingPageWrapper from "./ServicesOnboardingPageWrapper"

export interface IOnboardAnydayConnect {
    webshopIds: string[]
    merchantDetails: MixedMerchantDetails
    onOnboarded: () => void
    onGoBack: () => void
}

interface IAnydayApiKeyFormModel {
    apiKey: string
    privateKey: string
    acceptTerms: boolean
}

export const OnboardAnydayConnect = ({ webshopIds, merchantDetails, onOnboarded, onGoBack }: IOnboardAnydayConnect) => {
    const { t } = useTranslation()
    const { handleSubmit, register, formState } = useForm<IAnydayApiKeyFormModel>()
    const dispatch = useReduxDispatch()
    const env = useSelector(sharedConfigurationSelectors.environment)

    const selectedWebshopId = webshopIds?.length > 0 ? webshopIds[0] : useSelector(merchantSelectors.selectedWebshopId)

    if (!merchantDetails || !merchantDetails.webshops.length || !selectedWebshopId) {
        return null
    }
    const selectedWebshop = merchantDetails.webshops.find((w) => w.webshopId === selectedWebshopId)
    if (!selectedWebshop) {
        return null
    }

    const onSubmit: SubmitHandler<IAnydayApiKeyFormModel> = async (data) => {
        try {
            await dispatch(
                anydayOnboardingConnectThunk({
                    swMerchantId: merchantDetails.swMerchant.swMerchantId,
                    webshopId: selectedWebshop.webshopId,
                    anydayApiKey: data.apiKey,
                    anydayPrivateKey: data.privateKey,
                })
            )

            onOnboarded()
        } catch {
            //Catch to stop showing spinner
        }
    }

    return (
        <ServicesOnboardingPageWrapper customLogo={getImage("anydayLogo")} isWide>
            <div className="onboarding-anyday-connect">
                <div className="onboarding-anyday-connect__intro-box">{t("anydayOnboarding.toActivate")}</div>
                <CleanHtml
                    element="div"
                    className="onboarding-anyday-connect__intro-link"
                    html={t("anydayOnboarding.dontHaveOne")}
                />
                <CleanHtml
                    element="div"
                    className="text-normal blue"
                    html={t(env === "Production" ? "anydayOnboarding.apiKeyMessageProd" : "anydayOnboarding.apiKeyMessageTest")}
                />

                <Form onSubmit={handleSubmit(onSubmit)} className="onboarding-anyday-connect__form mt-5">
                    <FieldsetFormGroup field>
                        <CleanHtml
                            element="span"
                            className="text-normal blue"
                            html={t(
                                env === "Production"
                                    ? "anydayOnboarding.merchantApiKeyProd"
                                    : "anydayOnboarding.merchantApiKeyTest"
                            )}
                        />
                        <Input
                            name="apiKey"
                            innerRef={register(
                                valFuncRequiredAndPattern(
                                    validationPatterns.inputText,
                                    t(
                                        env === "Production"
                                            ? "anydayOnboarding.validation.missingApiKeyProd"
                                            : "anydayOnboarding.validation.missingApiKeyTest"
                                    )
                                )
                            )}
                        />
                    </FieldsetFormGroup>

                    <FieldsetFormGroup field>
                        <CleanHtml element="span" className="text-normal blue" html={t("anydayOnboarding.merchantPrivateKey")} />
                        <Input
                            name="privateKey"
                            innerRef={register(
                                valFuncRequiredAndPattern(
                                    validationPatterns.inputText,
                                    t("anydayOnboarding.validation.missingPrivateKey")
                                )
                            )}
                        />
                    </FieldsetFormGroup>

                    <ShowErrorMessages formState={formState} />

                    <SubmitButton
                        dark
                        noBorder
                        noBorderRadius
                        containerClass="onboarding-anyday-connect__submit-btn"
                        formState={formState}
                    >
                        {t("commononboardingtexts.submit")}
                    </SubmitButton>
                    <div className="onboarding-anyday-connect__back-link">
                        <a
                            onClick={() => {
                                onGoBack()
                            }}
                        >
                            {t("commononboardingtexts.back")}
                        </a>
                    </div>
                </Form>
            </div>
        </ServicesOnboardingPageWrapper>
    )
}

export default OnboardAnydayConnect
