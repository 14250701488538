import cn from "classnames"
import React from "react"
import { CleanHtml, getFormattedNumber } from "swiipe.portal.shared"
import { IMerchantDashboardValue } from "../../type/merchantDashboard/IMerchantDashboardValue"
import MerchantDashboardCompareDiff from "./MerchantDashboardCompareDiff"
import "./MerchantDashboardValueInText.scss"

interface IMerchantDashboardValueInText {
    text: string
    placeholder: string
    value?: IMerchantDashboardValue
    compareValue?: IMerchantDashboardValue
}

interface IMerchantDashboardValueInTextProps {
    items: IMerchantDashboardValueInText[]
}

const MerchantDashboardValueInText = ({ items }: IMerchantDashboardValueInTextProps) => {
    return (
        <div className={cn("merchant-dashboard-value-in-text")}>
            {items.map((item, index) => {
                if (!item.value) {
                    return null
                }
                return (
                    <div key={index} className="item">
                        <CleanHtml
                            element="div"
                            className="text"
                            html={item.text.replace(
                                item.placeholder,
                                "<span>" +
                                    getFormattedNumber(item.value.amount, { numberFormat: "forceTwoDecimals" }) +
                                    (item.value.isPercent ? "%" : "") +
                                    "</span>"
                            )}
                        />
                        {item.value !== undefined && item.compareValue !== undefined && (
                            <div className="data-compare">
                                <MerchantDashboardCompareDiff
                                    diffValuePoints={item.value.isPercent}
                                    value={item.value.amount}
                                    compareValue={item.compareValue.amount}
                                />
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default MerchantDashboardValueInText
