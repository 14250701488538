import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentRoute } from "react-navi"
import { useSelector } from "swiipe.portal.shared"
import ApplicationSubmittedDialog from "../../components/servicesonboarding/ApplicationSubmittedDialog"
import NoteDialog from "../../components/servicesonboarding/NoteDialog"
import OnboardAnydayConnect from "../../components/servicesonboarding/OnboardAnydayConnect"
import OnboardViabillByAuthentication from "../../components/servicesonboarding/OnboardViabillByAuthentication"
import OnboardingPricingDialog from "../../components/servicesonboarding/OnboardingPricingDialog"
import PlusSellOnboardingDisclaimer from "../../components/servicesonboarding/PlusSellOnboardingDisclaimer"
import WebshopSelectDialog from "../../components/servicesonboarding/WebshopSelectDialog"
import AnywhereMobileLogo from "../../images/swFeatureServices/anywhere-mobile-logo.svg"
import PlusSellLogo from "../../images/swFeatureServices/plussell-logo.png"
import ReturnSellLogo from "../../images/swFeatureServices/returnsell-logo.png"
import SwiipeWinbackLogo from "../../images/swFeatureServices/winback-logo.png"
import { useClearhausServicesOnboardingPageSettings } from "../../services/clearhausService"
import { getImage } from "../../services/imageLanguageService"
import { isAllTermsAccepted } from "../../services/legalService"
import { useMerchantPreOnboardingDetails } from "../../services/merchantOfferService"
import { navigationService } from "../../services/navigationService"
import {
    IOnboardingComponentsConfigEntity,
    TOnFirstStepGoBack,
    TOnboardingType,
    getServiceNameFromOnboardingType,
    getTermsTypeForOnboardingType,
    onboardingTypeIsAnyOf,
    useServicesOnboardingPage,
} from "../../services/onboardingService"
import { allWebshopsOnboardedForProvider } from "../../services/paymentService"
import { StoreState } from "../../store/StoreState"
import { legalSelectors } from "../../store/reducers/legalReducer"
import { IMerchantPreOnboardingDetails } from "../../store/reducers/merchantPreOnboardingSlice"
import { merchantSelectors } from "../../store/reducers/merchantReducer"
import { IMerchantProviderConfig, merchantServicesSelectors } from "../../store/reducers/merchantServicesReducer"
import { userRelationSelectors } from "../../store/reducers/userRelationReducer"
import { ensureTermsStatusThunk } from "../../store/thunks/termsThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { MixedMerchantDetails } from "../../type/mixedmerchantdetails"
import A2aOnboardingPage from "../a2a/A2aOnboardingPage"
import ApplePayOnboardingPage from "../applepay/ApplePayOnboardingPage"
import DankortOnboardingPage from "../dankort/DankortOnboardingPage"
import ForbrugsforeningenOnboardingPage from "../forbrugsforeningen/ForbrugsforeningenOnboardingPage"
import KlarnaOnboardingPage from "../klarna/KlarnaOnboardingPage"
import MobilepayOnlineOnboardingPage from "../mobilepay/MobilepayOnlineOnboardingPage"
import ResursOnboardingPage from "../resurs/ResursOnboardingPage"
import SwishOnboardingPage from "../swish/SwishOnboardingPage"
import VippsOnboardingPage from "../vipps/VippsOnboardingPage"
import SwiipePspOnboardingPage from "./SwiipePspOnboardingPage"
import "./SwiipeServicesOnboardingPage.scss"
import WinbackOnboardingPage from "./WinbackOnboardingPage"
import AnalyticsOnboardingPage from "./analytics/AnalyticsOnboardingPage"
import { AnywhereMobileOnboardingPage } from "./anywhereMobile/AnywhereMobileOnboardingPage"
import { ClearhausOnboardingAsExistingUserPage } from "./clearhausOnboarding/ClearhausOnboardingAsExistingUserPage"
import ClearhausOnboardingPage from "./clearhausOnboarding/ClearhausOnboardingPage"
import PlusSellOnboardingPage from "./plusSell/PlusSellOnboardingPage"
import ReturnSellOnboardingPage from "./returnSell/ReturnSellOnboardingPage"
import ViabillOnboardingPage from "./viabillOnboarding/ViabillOnboardingPage"

interface ISwiipeServicesOnboardingPageProps {
    onboardingType: TOnboardingType
}
interface ISwiipeServicesOnboardingInnerProps {
    merchantDetails: MixedMerchantDetails
    onboardingType: TOnboardingType
    preOnboardingDetails: IMerchantPreOnboardingDetails
}

const SwiipeServicesOnboardingPage = ({ onboardingType }: ISwiipeServicesOnboardingPageProps) => {
    const merchantDetails = useSelector(merchantSelectors.merchantDetails)
    const preOnboardingDetails = useMerchantPreOnboardingDetails()

    if (!merchantDetails || !preOnboardingDetails) {
        return null
    }

    const webshops = merchantDetails.webshops || []
    if (!webshops.length) {
        navigationService.navigate("/plugins")
        return null
    }

    return (
        <SwiipeServicesOnboardingInner
            merchantDetails={merchantDetails}
            onboardingType={onboardingType}
            preOnboardingDetails={preOnboardingDetails}
        />
    )
}

type TUsingServiceAlready = "viabill" | "clearhaus"
const SwiipeServicesOnboardingInner = ({
    merchantDetails,
    onboardingType,
    preOnboardingDetails,
}: ISwiipeServicesOnboardingInnerProps) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()
    const currentRoute = useCurrentRoute()
    const [webshopIds, setWebshopIds] = useState<string[]>([])
    const [usingServiceAlready, setUsingServiceAlready] = useState<TUsingServiceAlready | undefined>()

    const flowType = currentRoute.url.query.flowType as "merchantOffer" | undefined
    const currentUserRelation = useSelector(userRelationSelectors.currentUserRelation)
    const swiipePlan = useSelector(merchantSelectors.swiipePlan)
    const termsStatuses = useSelector(legalSelectors.termsStatuses)
    //onboardingStatuses are getting fetched in <WebshopSelectDialog>
    const onboardingStatuses = useSelector<StoreState, IMerchantProviderConfig | undefined>((state) =>
        merchantServicesSelectors.merchantStatuses(state, merchantDetails.swMerchant.swMerchantId)
    )

    const onboardedForClearhaus = allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "CreditCard", "Clearhaus")
    const needsClearhausOnboardingFirst: TOnboardingType[] = [
        "dankort",
        "mpo",
        "forbrugsforeningen",
        "viabill",
        "swish",
        "vipps",
        "applepay",
        "klarna",
    ]

    const [termsInitiallyAccepted, setTermsInitiallyAccepted] = useState<boolean | undefined>()
    useEffect(() => {
        setTermsInitiallyAccepted(undefined)
        setUsingServiceAlready(undefined)
    }, [currentUserRelation])
    useEffect(() => {
        if (!termsStatuses) {
            dispatch(ensureTermsStatusThunk(false))
            return
        }
        if (typeof termsInitiallyAccepted === "undefined") {
            setTermsInitiallyAccepted(isAllTermsAccepted([getTermsTypeForOnboardingType(onboardingType)], termsStatuses ?? []))
        }
    }, [termsStatuses, termsInitiallyAccepted])

    const configRef = useRef<IOnboardingComponentsConfigEntity[]>([])
    configRef.current = [
        {
            component: (
                <NoteDialog onGoBack={() => incrementStep("previousStep")} onGoNext={() => incrementStep("nextStep")}>
                    <>
                        <p className="block-heading text-center">
                            {t("clearhausonboarding.serviceNeedsClearhaus.canOnlyBeActivated", {
                                serviceName: getServiceNameFromOnboardingType(onboardingType),
                            })}
                        </p>
                        <p className="block-heading text-center">
                            {t("clearhausonboarding.serviceNeedsClearhaus.continueToSetupBoth")}
                        </p>
                    </>
                </NoteDialog>
            ),
            condition: !onboardedForClearhaus && onboardingTypeIsAnyOf(onboardingType, needsClearhausOnboardingFirst),
            id: "sharedNoteDialog",
        },
        {
            component: (
                <OnboardingPricingDialog
                    onboardingType="cards" //clearhaus pricing
                    onGoBack={() => incrementStep("previousStep")}
                    onGoNext={() => incrementStep("nextStep")}
                />
            ),
            condition:
                !termsInitiallyAccepted &&
                !onboardedForClearhaus &&
                (onboardingType === "cards" || onboardingTypeIsAnyOf(onboardingType, needsClearhausOnboardingFirst)),
            id: "acquiringPricingDialog",
        },
        {
            component: (
                <SwiipePspOnboardingPage
                    onGoBack={async () => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition:
                onboardingTypeIsAnyOf(onboardingType, [
                    "cards",
                    "dankort",
                    "mpo",
                    "forbrugsforeningen",
                    "viabill",
                    "anyday",
                    "swish",
                    "vipps",
                    "applepay",
                    "accountToAccount",
                    "resurs",
                    "klarna",
                ]) && !swiipePlan,
            id: "pspOnboarding",
        },
        {
            component: (
                <ClearhausOnboardingAsExistingUserPage
                    webshopIds={webshopIds}
                    merchantDetails={merchantDetails}
                    onboardingType={onboardingType}
                    flowType={flowType}
                    onGoBack={async () => incrementStep("previousStep")}
                    onNotExistingUser={() => incrementStep("nextStep")}
                    onOnboarded={() => {
                        setUsingServiceAlready("clearhaus")

                        if (onboardingType === "cards") {
                            navigationService.navigate("/plugins")
                            return
                        }
                        incrementStep("nextStep")
                    }}
                    preOnboardingDetails={preOnboardingDetails}
                />
            ),
            condition:
                !onboardedForClearhaus &&
                (onboardingType === "cards" || onboardingTypeIsAnyOf(onboardingType, needsClearhausOnboardingFirst)),
            id: "clearhausWithoutDataOnboarding",
        },
        {
            component: (
                <ClearhausOnboardingPage
                    withWebshopIds={webshopIds}
                    onboardingType={onboardingType}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => {
                        if (onboardingType === "cards") {
                            navigationService.navigate("/plugins")
                            return
                        }
                        incrementStep("nextStep")
                    }}
                />
            ),
            condition:
                !onboardedForClearhaus &&
                (onboardingType === "cards" || onboardingTypeIsAnyOf(onboardingType, needsClearhausOnboardingFirst)),
            id: "clearhausOnboarding",
        },
        {
            component: (
                <OnboardingPricingDialog
                    onboardingType={onboardingType}
                    onGoBack={() => incrementStep("previousStep")}
                    onGoNext={() => incrementStep("nextStep")}
                />
            ),
            condition: !termsInitiallyAccepted,
            id: "onboardingPricingDialog",
        },
        {
            component: (
                <DankortOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition:
                onboardingType === "dankort" &&
                !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "CreditCard", "DankortEpay"),
            id: "dankortOnboarding",
        },
        {
            component: (
                <ForbrugsforeningenOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition:
                onboardingType === "forbrugsforeningen" &&
                !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "CreditCard", "ForbrugsforeningenEpay"),
            id: "forbrugsforeningenOnboarding",
        },
        {
            component: (
                <OnboardViabillByAuthentication
                    webshopIds={webshopIds}
                    merchantDetails={merchantDetails}
                    onGoBack={() => incrementStep("previousStep")}
                    onGoNext={() => incrementStep("nextStep")}
                    onOnboarded={() => {
                        incrementStep("nextStep")
                        setUsingServiceAlready("viabill")
                    }}
                />
            ),
            condition:
                onboardingType === "viabill" &&
                !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "Viabill", "Viabill"),
            id: "hasViabillAlready",
        },
        {
            component: (
                <OnboardAnydayConnect
                    webshopIds={webshopIds}
                    merchantDetails={merchantDetails}
                    onOnboarded={() => {
                        incrementStep("nextStep")
                    }}
                    onGoBack={() => {
                        incrementStep("previousStep")
                    }}
                />
            ),
            condition:
                onboardingType === "anyday" &&
                !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "Anyday", "Anyday"),
            id: "anydayOnboarding",
        },
        {
            component: (
                <ViabillOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition:
                onboardingType === "viabill" &&
                !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "Viabill", "Viabill"),
            id: "viabillOnboarding",
        },
        {
            component: (
                <SwishOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition:
                onboardingType === "swish" && !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "Swish", "Swish"),
            id: "swishOnboarding",
        },
        {
            component: (
                <AnalyticsOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={async () => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition: onboardingType === "externalAnalytics",
            id: "externalAnalyticsOnboarding",
        },
        {
            component: (
                <VippsOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition:
                onboardingType === "vipps" &&
                !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "Vipps", "Clearhaus"),
            id: "vippsOnboarding",
        },
        {
            component: (
                <ApplePayOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={async () => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition:
                onboardingType === "applepay" &&
                !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "ApplePay", "Clearhaus"),
            id: "applePayOnboarding",
        },
        {
            component: (
                <KlarnaOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition:
                onboardingType === "klarna" &&
                !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "KlarnaPayLater", "Klarna") &&
                !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "KlarnaFinancing", "Klarna"),
            id: "klarnaOnboarding",
        },
        {
            component: (
                <MobilepayOnlineOnboardingPage
                    webshopIds={webshopIds}
                    hasClearhausAlready={usingServiceAlready === "clearhaus"}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition:
                onboardingType === "mpo" &&
                !allWebshopsOnboardedForProvider(onboardingStatuses, webshopIds, "MobilePayOnline", "MobilePayOnline"),
            id: "mpoOnboarding",
        },
        {
            component: <A2aOnboardingPage webshopIds={webshopIds} onGoBack={() => incrementStep("previousStep")} />,
            condition: onboardingType === "accountToAccount",
            id: "a2aOnboarding",
        },
        {
            component: <ResursOnboardingPage webshopIds={webshopIds} onGoBack={() => incrementStep("previousStep")} />,
            condition: onboardingType === "resurs",
            id: "resursOnboarding",
        },
        {
            component: (
                <AnywhereMobileOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition: onboardingType === "anywhereMobile",
            id: "anywhereMobileOnboarding",
        },
        {
            component: (
                <PlusSellOnboardingDisclaimer
                    onGoBack={() => incrementStep("previousStep")}
                    onGoNext={() => incrementStep("nextStep")}
                />
            ),
            condition: onboardingType === "plusSell",
            id: "plusSellDisclaimer",
        },
        {
            component: (
                <PlusSellOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition: onboardingType === "plusSell",
            id: "plusSellOnboarding",
        },
        {
            component: (
                <ReturnSellOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition: onboardingType === "returnSell",
            id: "returnSellOnboarding",
        },
        {
            component: (
                <WinbackOnboardingPage
                    webshopIds={webshopIds}
                    onGoBack={() => incrementStep("previousStep")}
                    onOnboarded={() => incrementStep("nextStep")}
                />
            ),
            condition: onboardingType === "winback",
            id: "winbackOnboarding",
        },
        {
            component: (
                <ApplicationSubmittedDialog
                    firstLine={(() => {
                        switch (onboardingType) {
                            case "winback":
                                return "winbackonboarding.onboardedtext1"
                            case "plusSell":
                                return "plusSellOnboarding.onboardedtext1"
                            case "returnSell":
                                return "returnSellOnboarding.onboardedtext1"
                            case "vipps":
                                return "vippsonboarding.onboardedtext1"
                            case "klarna":
                                return "klarnaonboarding.onboardedText1"
                            case "applepay":
                                return "applepayonboarding.onboardedText1"
                            case "viabill":
                                return usingServiceAlready === "viabill"
                                    ? "viabillOnboarding.onboardedText1.existingViabill"
                                    : "viabillOnboarding.onboardedText1.registeredViabill"
                            case "anyday":
                                return "anydayOnboarding.onboardedText1.existingAnyday"
                            case "anywhereMobile":
                                return "anywhereMobileOnboarding.onboarded.firstLine"
                            case "externalAnalytics":
                                return "analyticsOnboarding.onboarded.firstLine"
                            default:
                                return "clearhausonboarding.messagetitles.applicationsubmited1"
                        }
                    })()}
                    secondLine={(() => {
                        switch (onboardingType) {
                            case "winback":
                                return "winbackonboarding.onboardedtext2"
                            case "plusSell":
                                return "plusSellOnboarding.onboardedtext2"
                            case "returnSell":
                                return "returnSellOnboarding.onboardedtext2"
                            case "vipps":
                                return "vippsonboarding.onboardedtext2"
                            case "klarna":
                                return "klarnaonboarding.onboardedText2"
                            case "viabill":
                                return usingServiceAlready === "viabill"
                                    ? "viabillOnboarding.onboardedText2.existingViabill"
                                    : "viabillOnboarding.onboardedText2.registeredViabill"
                            case "anyday":
                                return "anydayOnboarding.onboardedText2.existingAnyday"
                            case "anywhereMobile":
                                return "anywhereMobileOnboarding.onboarded.secondLine"
                            case "externalAnalytics":
                                return "analyticsOnboarding.onboarded.secondLine"
                            default:
                                return "clearhausonboarding.messagetitles.applicationsubmited2"
                        }
                    })()}
                    logo={(() => {
                        switch (onboardingType) {
                            case "winback":
                                return SwiipeWinbackLogo
                            case "plusSell":
                                return PlusSellLogo
                            case "returnSell":
                                return ReturnSellLogo
                            case "viabill":
                                return getImage("viabillLogo")
                            case "anyday":
                                return getImage("anydayLogo")
                            case "anywhereMobile":
                                return AnywhereMobileLogo
                            default:
                                return undefined
                        }
                    })()}
                    disableHelp={onboardingTypeIsAnyOf(onboardingType, ["plusSell", "viabill", "anyday"])}
                    overrideSubmitAction={
                        onboardingType === "plusSell"
                            ? () => {
                                  navigationService.navigate("/plussell_details")
                              }
                            : undefined
                    }
                    overrideSubmitText={onboardingType === "plusSell" ? "plusSellOnboarding.seeSnippet" : undefined}
                    showCloseBtn={onboardingType === "plusSell" ? true : undefined}
                    logoWidth={onboardingTypeIsAnyOf(onboardingType, ["plusSell", "winback", "returnSell"]) ? "180px" : undefined}
                />
            ),
            condition: true,
            id: "submittedDialog",
        },
    ]

    const [onFirstStepGoBack, setOnFirstStepGoBack] = useState<TOnFirstStepGoBack>("showWebshopSelectAgain")
    const { isWebshopsSelected, setIsWebshopsSelected, addStepShouldSkip, addStepPreventSkip, incrementStep, getStepToShow } =
        useServicesOnboardingPage(configRef, merchantDetails, onFirstStepGoBack)

    // only for "cards" (Clearhaus) onboarding type
    const { webshopsToPreselect, skipWebshopSelectStep } = useClearhausServicesOnboardingPageSettings(
        webshopIds,
        merchantDetails,
        onboardingType,
        addStepPreventSkip,
        addStepShouldSkip
    )
    useEffect(() => {
        if (!skipWebshopSelectStep) {
            return
        }
        setOnFirstStepGoBack("navigateToPluginsPage")
    }, [skipWebshopSelectStep])

    return (
        <>
            <WebshopSelectDialog
                isWebshopsSelectedState={[isWebshopsSelected, (isWebshopsSelected) => setIsWebshopsSelected(isWebshopsSelected)]}
                merchantDetails={merchantDetails}
                onboardingType={onboardingType}
                onWebshopIdsChanged={(selectedWebshopIds) => setWebshopIds(selectedWebshopIds)}
                webshopsToPreselect={webshopsToPreselect}
                skipStepAfterWebshopsPreselected={skipWebshopSelectStep}
            />

            {!!webshopIds.length && getStepToShow()}
        </>
    )
}

export default SwiipeServicesOnboardingPage
