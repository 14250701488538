import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FilterCheckbox, currencies, getFormattedNumber, numberInputOnKeyDown, useSelector } from "swiipe.portal.shared"
import {
    getSelectedValueForCheckboxDropDown,
    getSelectedValueForFromToDropDown,
    onChangeOrderFilters,
} from "../../services/orderFiltersService"
import { getTransactionStatusTranslation } from "../../services/orderService"
import { orderSelectors } from "../../store/reducers/orderReducer"
import { updateOrderFiltersThunk } from "../../store/thunks/orderThunks"
import { useReduxDispatch } from "../../store/useReduxDispatch"
import { ESubscriptionOrderType, OrderActionsFilter, OrderPaymentTypeFilter, TOrderStatusFilter } from "../../type/Order"
import { allTransactionTypes } from "../../type/Transaction"
import ApplyResetButtonsBlock from "../buttons/ApplyResetButtonsBlock"
import DropDownWithChildren from "../dropdowns/DropDownWithChildren"
import FromToFilterInputs from "../form/input/FromToFilterInputs"
import DateRangePicker from "../form/input/date/DateRangePicker"
import "./OrdersFilters.scss"

type TFilterDropDownNames =
    | "date"
    | "currency"
    | "amount"
    | "payment"
    | "actions"
    | "txStatus"
    | "subscriptionOrderTypes"
    | "none"

interface IOrdersFilterBox {
    showFilters: boolean
}

interface IFilterCheckboxEntity {
    label: string
    value: any // TODO: Make generics type: IFilterCheckboxEntity<T>
}

const OrdersFilters = ({ showFilters }: IOrdersFilterBox) => {
    const { t } = useTranslation()
    const dispatch = useReduxDispatch()

    const [activeDropdown, setActiveDropdown] = useState<TFilterDropDownNames>("none")

    const { register, setValue, getValues } = useForm<any>()

    const currentFilters = useSelector(orderSelectors.getOrderFilter)

    const currencyCheckboxes: IFilterCheckboxEntity[] = currencies.map((c) => {
        return {
            label: `${c.displayTitle} (${c.symbol})`,
            value: c.id,
        }
    })

    const actionsCheckboxes: IFilterCheckboxEntity[] = [
        { label: t("transactions.action.capture"), value: OrderActionsFilter.CanCapture },
        { label: t("transactions.action.cancel"), value: OrderActionsFilter.CanCancel },
        { label: t("transactions.action.refund"), value: OrderActionsFilter.CanRefund },
    ]

    const paymentCheckboxes: IFilterCheckboxEntity[] = [
        {
            label: "MasterCard",
            value: {
                paymentType: "CreditCard",
                paymentSubType: "MasterCard",
            } as OrderPaymentTypeFilter,
        },
        {
            label: "MobilePay",
            value: {
                paymentType: "MobilePayOnline",
            } as OrderPaymentTypeFilter,
        },
        {
            label: "Visa",
            value: {
                paymentType: "CreditCard",
                paymentSubType: "Visa",
            } as OrderPaymentTypeFilter,
        },
        {
            label: "Dankort",
            value: {
                paymentType: "CreditCard",
                paymentSubType: "Dankort",
            } as OrderPaymentTypeFilter,
        },
        {
            label: "Forbrugsforeningen",
            value: {
                paymentType: "CreditCard",
                paymentSubType: "Forbrugsforeningen",
            } as OrderPaymentTypeFilter,
        },
        {
            label: t("transactions.paymentTypes.accountToAccount"),
            value: {
                paymentType: "AccountToAccount",
            } as OrderPaymentTypeFilter,
        },
        {
            label: "ViaBill",
            value: {
                paymentType: "Viabill",
            } as OrderPaymentTypeFilter,
        },
        {
            label: "Anyday",
            value: {
                paymentType: "Anyday",
            } as OrderPaymentTypeFilter,
        },
        {
            label: "Swish",
            value: {
                paymentType: "Swish",
            } as OrderPaymentTypeFilter,
        },
        {
            label: "Vipps",
            value: {
                paymentType: "Vipps",
            } as OrderPaymentTypeFilter,
        },
        {
            label: "Apple Pay",
            value: {
                paymentType: "ApplePay",
            } as OrderPaymentTypeFilter,
        },
        {
            label: t("transactions.paymentTypes.klarnapaylater"),
            value: {
                paymentType: "KlarnaPayLater",
            } as OrderPaymentTypeFilter,
        },
        {
            label: t("transactions.paymentTypes.klarnaovertime"),
            value: {
                paymentType: "KlarnaFinancing",
            } as OrderPaymentTypeFilter,
        },
        {
            label: t("serviceNames.resursInvoice"),
            value: {
                paymentType: "ResursInvoice",
            } as OrderPaymentTypeFilter,
        },
        {
            label: t("serviceNames.resursPartPayment"),
            value: {
                paymentType: "ResursPartPayment",
            } as OrderPaymentTypeFilter,
        },
        {
            label: t("serviceNames.resursRevolvingCredit"),
            value: {
                paymentType: "ResursRevolvingCredit",
            } as OrderPaymentTypeFilter,
        },
    ]
    const subscriptionOrderTypeCheckboxes: IFilterCheckboxEntity[] = [
        { label: t("transactions.subscriptions.orderTypes.initial"), value: ESubscriptionOrderType.InitialPayment },
        { label: t("transactions.subscriptions.orderTypes.subsequent"), value: ESubscriptionOrderType.SubsequentPayment },
        { label: t("transactions.subscriptions.orderTypes.update"), value: ESubscriptionOrderType.UpdatePayment },
    ]

    const [selectedDateRangeStr, setSelectedDateRangeStr] = useState<string | undefined>(undefined)

    useEffect(() => {
        //Drop input values
        setValue("amount-from", "")
        setValue("amount-to", "")
        if (!showFilters) {
            dispatch(
                updateOrderFiltersThunk((filters) => {
                    filters.dateFrom = undefined
                    filters.dateTo = undefined
                    filters.amountFrom = undefined
                    filters.amountTo = undefined
                    filters.currency = undefined
                    filters.transactionAction = undefined
                    filters.transactionPaymentType = undefined
                    filters.orderId = undefined
                })
            )

            setSelectedDateRangeStr(undefined)
        }
    }, [showFilters])

    return (
        <div className="options-filter-box">
            <DropDownWithChildren
                placeholder={t("transactions.date")}
                selectedValue={selectedDateRangeStr}
                active={activeDropdown === "date"}
                setActiveState={(active) => setActiveDropdown(active ? "date" : "none")}
                boxWidth={264}
            >
                <DateRangePicker
                    initialFromDate={undefined}
                    initialToDate={undefined}
                    allowEmptyDates
                    onDateRangeUpdated={(dateFromUTC, dateToUTC, rangeStringified) => {
                        setSelectedDateRangeStr(rangeStringified)
                        setActiveDropdown("none")
                        dispatch(
                            updateOrderFiltersThunk((filters) => {
                                filters.dateFrom = dateFromUTC?.toISOString() || undefined
                                filters.dateTo = dateToUTC?.toISOString() || undefined
                            })
                        )
                    }}
                />
            </DropDownWithChildren>
            <DropDownWithChildren
                placeholder={t("transactions.currency")}
                selectedValue={getSelectedValueForCheckboxDropDown(currentFilters.currency)}
                active={activeDropdown === "currency"}
                setActiveState={(active) => setActiveDropdown(active ? "currency" : "none")}
            >
                {currencyCheckboxes.map((cc) => (
                    <FilterCheckbox
                        key={cc.value}
                        label={cc.label}
                        showFilters={showFilters}
                        onChange={(checked: boolean) =>
                            onChangeOrderFilters(cc.value, "currency", checked, currentFilters, dispatch)
                        }
                    />
                ))}
            </DropDownWithChildren>
            <DropDownWithChildren
                placeholder={t("transactions.amount")}
                selectedValue={getSelectedValueForFromToDropDown(
                    currentFilters.amountFrom
                        ? getFormattedNumber(currentFilters.amountFrom, { numberFormat: "forceTwoDecimals" })
                        : undefined,
                    currentFilters.amountTo
                        ? getFormattedNumber(currentFilters.amountTo, { numberFormat: "forceTwoDecimals" })
                        : undefined
                )}
                active={activeDropdown === "amount"}
                setActiveState={(active) => setActiveDropdown(active ? "amount" : "none")}
            >
                <FromToFilterInputs
                    labelFrom={t("transactions.filters.from")}
                    labelTo={t("transactions.filters.to")}
                    inputNameFrom="amount-from"
                    inputNameTo="amount-to"
                    filterNameFrom="amountFrom"
                    filterNameTo="amountTo"
                    placeholder={getFormattedNumber(0, { numberFormat: "forceTwoDecimals" })}
                    type="number"
                    step={0.01}
                    inputMode="decimal"
                    onKeyDownInputs={(e) => numberInputOnKeyDown({ event: e, allowNegativeNumbers: false, isDecimal: true })}
                    register={register}
                />
                <ApplyResetButtonsBlock
                    onApply={() => {
                        const amountFrom = parseFloat(getValues()["amount-from"])
                        const amountTo = parseFloat(getValues()["amount-to"])

                        dispatch(
                            updateOrderFiltersThunk((filters) => {
                                filters.amountFrom = amountFrom ? amountFrom : undefined
                                setValue("amount-from", amountFrom?.toString())

                                filters.amountTo = amountTo ? amountTo : undefined
                                setValue("amount-to", amountTo?.toString())
                            })
                        )
                    }}
                    onReset={async () =>
                        dispatch(
                            updateOrderFiltersThunk((filters) => {
                                filters.amountFrom = undefined
                                setValue("amount-from", "")

                                filters.amountTo = undefined
                                setValue("amount-to", "")
                            })
                        )
                    }
                    applyText={t("transactions.show")}
                    resetText={t("transactions.clear")}
                />
            </DropDownWithChildren>
            <DropDownWithChildren
                placeholder={t("transactions.payment")}
                boxWidth={155}
                selectedValue={getSelectedValueForCheckboxDropDown(
                    currentFilters.transactionPaymentType?.map((tpt: OrderPaymentTypeFilter) =>
                        tpt.paymentType === "CreditCard" ? tpt.paymentSubType! : tpt.paymentType
                    )
                )}
                active={activeDropdown === "payment"}
                setActiveState={(active) => setActiveDropdown(active ? "payment" : "none")}
            >
                {paymentCheckboxes.map((pc) => (
                    <FilterCheckbox
                        key={pc.label}
                        label={pc.label}
                        showFilters={showFilters}
                        onChange={(checked: boolean) =>
                            onChangeOrderFilters(pc.value, "transactionPaymentType", checked, currentFilters, dispatch)
                        }
                    />
                ))}
            </DropDownWithChildren>
            <DropDownWithChildren
                placeholder={t("transactions.actions")}
                selectedValue={getSelectedValueForCheckboxDropDown(
                    currentFilters.transactionAction?.map((ta) => actionsCheckboxes.find((ac) => ac.value === ta)?.label || "")
                )}
                active={activeDropdown === "actions"}
                setActiveState={(active) => setActiveDropdown(active ? "actions" : "none")}
            >
                {actionsCheckboxes.map((ac) => (
                    <FilterCheckbox
                        key={ac.value}
                        label={ac.label}
                        showFilters={showFilters}
                        onChange={(checked: boolean) =>
                            onChangeOrderFilters(ac.value, "transactionAction", checked, currentFilters, dispatch)
                        }
                    />
                ))}
            </DropDownWithChildren>
            {(!currentFilters.orderStatus || currentFilters.orderStatus === TOrderStatusFilter.AuthFailed) && (
                <DropDownWithChildren
                    width={160}
                    placeholder={t("transactions.txStatus")}
                    selectedValue={getSelectedValueForCheckboxDropDown(
                        currentFilters.txStatus?.map((ts) => getTransactionStatusTranslation(ts))
                    )}
                    active={activeDropdown === "txStatus"}
                    setActiveState={(active) => setActiveDropdown(active ? "txStatus" : "none")}
                >
                    {allTransactionTypes.map((cc) => (
                        <FilterCheckbox
                            key={cc}
                            label={getTransactionStatusTranslation(cc)}
                            showFilters={showFilters}
                            isChecked={currentFilters.txStatus?.some((s) => s === cc)}
                            onChange={(checked: boolean) =>
                                onChangeOrderFilters(cc, "txStatus", checked, currentFilters, dispatch)
                            }
                        />
                    ))}
                </DropDownWithChildren>
            )}
            <DropDownWithChildren
                placeholder={t("transactions.subscriptions.filterTitle")}
                selectedValue={getSelectedValueForCheckboxDropDown(
                    currentFilters.subscriptionOrderTypes?.map(
                        (sot) => subscriptionOrderTypeCheckboxes.find((sc) => sc.value === sot)?.label || ""
                    )
                )}
                active={activeDropdown === "subscriptionOrderTypes"}
                setActiveState={(active) => setActiveDropdown(active ? "subscriptionOrderTypes" : "none")}
            >
                {subscriptionOrderTypeCheckboxes.map((ac) => (
                    <FilterCheckbox
                        key={ac.value}
                        label={ac.label}
                        showFilters={showFilters}
                        onChange={(checked: boolean) =>
                            onChangeOrderFilters(ac.value, "subscriptionOrderTypes", checked, currentFilters, dispatch)
                        }
                    />
                ))}
            </DropDownWithChildren>
        </div>
    )
}

export default OrdersFilters
