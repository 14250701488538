import React from "react"
import { useTranslation } from "react-i18next"
import { CleanHtml, PageContainer, PageWrapper, StandardButton, TextButton } from "swiipe.portal.shared"
import PlusSellLogo from "../../images/swFeatureServices/plussell-logo.png"
import UnorderedListWithHints from "../sections/UnorderedListWithHints"
import "./PlusSellOnboardingDisclaimer.scss"

interface IPlusSellOnboardingDisclaimer {
    onGoBack: () => void
    onGoNext: () => void
}

const PlusSellOnboardingDisclaimer = ({ onGoBack, onGoNext }: IPlusSellOnboardingDisclaimer) => {
    const { t } = useTranslation()

    return (
        <PageWrapper>
            <PageContainer>
                <div className="plussell-disclaimer-container">
                    <div className="plussell-form-head">
                        <img className="plussell-logo" src={PlusSellLogo} />
                    </div>

                    <div className="plussell-info mt-5 mb-3">
                        <PlusSellDisclaimerListWithHints />

                        <StandardButton className="mt-5" dark noBorder noBorderRadius onClick={() => onGoNext()}>
                            {t("swiipeservicesonboarding.continue")}
                        </StandardButton>
                        <TextButton
                            text={t("commononboardingtexts.back")}
                            className="mt-4 mb-4"
                            onClick={async () => onGoBack()}
                        />
                    </div>
                </div>
            </PageContainer>
        </PageWrapper>
    )
}

export default PlusSellOnboardingDisclaimer

interface IPlusSellDisclaimerListWithHints {
    className?: string
}
export const PlusSellDisclaimerListWithHints = ({ className }: IPlusSellDisclaimerListWithHints) => {
    const { t } = useTranslation()

    return (
        <UnorderedListWithHints
            className={className}
            heading={t("plusSellDisclaimer.heading")}
            listRows={[
                {
                    title: t("plusSellDisclaimer.recommendationEngine.title"),
                    hint: (
                        <>
                            <p>{t("plusSellDisclaimer.recommendationEngine.forBestPerformance")}</p>
                            <div className="pl-4 mb-2">
                                <b>{t("plusSellDisclaimer.recommendationEngine.helloRetail")}</b>
                                <br />
                                <CleanHtml element="span" html={t("plusSellDisclaimer.recommendationEngine.helloRetailGuide")} />
                            </div>
                            <div className="pl-4">
                                <b>{t("plusSellDisclaimer.recommendationEngine.clerk")}</b>
                                <br />
                                <CleanHtml element="span" html={t("plusSellDisclaimer.recommendationEngine.clerkGuide")} />
                            </div>
                        </>
                    ),
                },
                {
                    title: t("plusSellDisclaimer.doItYourselfSolution.title"),
                    hint: (
                        <>
                            <p>{t("plusSellDisclaimer.doItYourselfSolution.alternativeWays")}</p>
                            <ol>
                                <li className="mb-2">
                                    <b>{t("plusSellDisclaimer.doItYourselfSolution.usingPlatformsFunctionality")}</b>{" "}
                                    <CleanHtml
                                        element="span"
                                        html={t("plusSellDisclaimer.doItYourselfSolution.crossSellGuide")}
                                    />
                                </li>
                                <li>
                                    <b>{t("plusSellDisclaimer.doItYourselfSolution.usingSwiipeSettings")}</b>{" "}
                                    <CleanHtml
                                        element="span"
                                        html={t("plusSellDisclaimer.doItYourselfSolution.customRecommendationsGuide")}
                                    />
                                </li>
                            </ol>
                        </>
                    ),
                },
            ]}
        />
    )
}
