import { TCreditCardType, TPayment, getText } from "swiipe.portal.shared"
import PlusSellIcon from "../images/swFeatureServices/plussell-icon.png"
import PlusSellInbetweenIcon from "../images/swFeatureServices/plussell-inbetween-icon.png"
import PlusSellVoidedIcon from "../images/swFeatureServices/plussell-voided-icon.png"
import ReturnSellIcon from "../images/swFeatureServices/returnsell-icon.png"
import A2aLogo from "../images/swPaymentTypes/account-to-account-icon.png"
import AnydayIcon from "../images/swPaymentTypes/anyday-icon.svg"
import ApplePayIcon from "../images/swPaymentTypes/applepay-icon.svg"
import KlarnaLogo from "../images/swPaymentTypes/klarna-logo.svg"
import MobilePayLogo from "../images/swPaymentTypes/mobilepay-icon.png"
import ResursIcon from "../images/swPaymentTypes/resurs-icon.webp"
import SwishLogo from "../images/swPaymentTypes/swish-icon.png"
import ViabillLogo from "../images/swPaymentTypes/viabill-logo.png"
import VippsIcon from "../images/swPaymentTypes/vipps-icon.svg"
import { addModalThunk } from "../store/thunks/modalThunks"
import { ReduxDispatch } from "../store/useReduxDispatch"
import { IReportOrderActionData } from "../type/IReportOrderActionData"
import { ESubscriptionOrderType, Order, TOrderStatus } from "../type/Order"
import { TransactionStatus, TransactionType } from "../type/Transaction"
import { getLogoByCardType, resolvePaymentTypeName } from "./paymentService"

export const getPlatformOrderIdFormatted = (platformOrderId?: string) => {
    if (!platformOrderId) {
        return ""
    }

    return platformOrderId.replace(/^0+/, "")
}

export const getIconPaddingFromPaymentType = (paymentType: TPayment, paymentSubType?: string) => {
    switch (paymentType) {
        case "Anyday":
            return 2
        default:
            return 0
    }
}

export const getIconFromPaymentType = (paymentType: TPayment, paymentSubType?: string) => {
    switch (paymentType) {
        case "CreditCard": {
            return getLogoByCardType(paymentSubType as TCreditCardType)
        }
        case "MobilePayOnline":
            return MobilePayLogo
        case "AccountToAccount":
            return A2aLogo
        case "Swish":
            return SwishLogo
        case "Viabill":
            return ViabillLogo
        case "Anyday":
            return AnydayIcon
        case "KlarnaPayLater":
        case "KlarnaFinancing":
            return KlarnaLogo
        case "Vipps":
            return VippsIcon
        case "ApplePay":
            return ApplePayIcon
        case "ResursInvoice":
            return ResursIcon
        case "ResursPartPayment":
            return ResursIcon
        case "ResursRevolvingCredit":
            return ResursIcon

        default:
            return ""
    }
}

export const resolvePaymentTypeNameForOrder = (po: Order): string => {
    return resolvePaymentTypeName(po.paymentType)
}

export const getTranslationForPaymentType = (po: Order) => {
    const translationKeys: { [key in TPayment]?: string } = {
        AccountToAccount: "transactions.accountnumber",
        CreditCard: "transactions.card",
    }
    const translationKey = translationKeys[po.paymentType]
    if (!translationKey) {
        return ""
    }
    return getText(translationKey)
}

export function getSubscriptionOrderTypeText(subscriptionOrderType: ESubscriptionOrderType, isLong: boolean) {
    if (isLong) {
        return {
            [ESubscriptionOrderType.InitialPayment]: getText("transactions.subscriptions.orderTypesLong.initial"),
            [ESubscriptionOrderType.SubsequentPayment]: getText("transactions.subscriptions.orderTypesLong.subsequent"),
            [ESubscriptionOrderType.UpdatePayment]: getText("transactions.subscriptions.orderTypesLong.update"),
        }[subscriptionOrderType]
    }
    return {
        [ESubscriptionOrderType.InitialPayment]: getText("transactions.subscriptions.orderTypes.initial"),
        [ESubscriptionOrderType.SubsequentPayment]: getText("transactions.subscriptions.orderTypes.subsequent"),
        [ESubscriptionOrderType.UpdatePayment]: getText("transactions.subscriptions.orderTypes.update"),
    }[subscriptionOrderType]
}

export const resolvePaymentTypeText = (po: Order) => {
    let paymentTypeText: string

    const getTypeWithSubtypeText = () => {
        return !po.paymentSubType ? po.paymentInfo ?? "" : po.paymentType + " " + po.paymentSubType
    }

    const getSubtypeWithInfoText = () => {
        return !po.paymentSubType ? po.paymentInfo ?? "" : po.paymentSubType + " " + (po.paymentInfo ?? "")
    }

    switch (po.paymentType) {
        case "MobilePayOnline":
        case "ApplePay":
        case "Vipps":
            paymentTypeText = getTypeWithSubtypeText()
            break
        default:
            paymentTypeText = getSubtypeWithInfoText()
    }

    if (po.subscriptionOrderType) {
        paymentTypeText = getText("transactions.subscriptions.createdViaPaymentType", {
            orderType: getSubscriptionOrderTypeText(po.subscriptionOrderType, true),
            paymentType: paymentTypeText,
        })
    }

    return paymentTypeText
}

export const shouldShowPlusSellIcon = (po: Order) => {
    return po.plusSellParentOrderId || po.plusSellChildOrderId
}

export const resolvePlusSellText = (po: Order) => {
    if (!po.plusSellParentOrderId && po.plusSellChildOrderId) {
        return getText("transactions.plusSell.orginalOrderIconDescription")
    }

    if (po.plusSellParentOrderId && !po.plusSellChildOrderId) {
        return getText("transactions.plusSell.plusSellOrderIconDescription")
    }

    return getText("transactions.plusSell.InbetweenOrderIconDescription")
}

export const resolvePlusSellIcon = (po: Order) => {
    if (!po.plusSellParentOrderId && po.plusSellChildOrderId) {
        return PlusSellVoidedIcon
    }

    if (po.plusSellParentOrderId && !po.plusSellChildOrderId) {
        return PlusSellIcon
    }

    return PlusSellInbetweenIcon
}

export const shouldShowReturnSellIcon = (po: Order) => {
    return po.returnSellParentOrderId
}

export const resolveReturnSellText = (po: Order) => {
    return getText("transactions.returnSell.returnSellOrderIconDescription")
}

export const resolveReturnSellIcon = (po: Order) => {
    return ReturnSellIcon
}

export const resolveOrderStatusText = (po: Order) => {
    switch (po.orderStatus) {
        case TOrderStatus.AuthFailed:
            return getText("transactions.orderStatus.authFailed")
        case TOrderStatus.Authorized:
            return getText("transactions.orderStatus.authorized")
        case TOrderStatus.Captured:
            return getText("transactions.orderStatus.captured")
        case TOrderStatus.PartiallyCaptured:
            return getText("transactions.orderStatus.partiallyCaptured")
        case TOrderStatus.PartiallyRefunded:
            return getText("transactions.orderStatus.partiallyRefunded")
        case TOrderStatus.Refunded:
            return getText("transactions.orderStatus.refunded")
        case TOrderStatus.Voided:
            return getText("transactions.orderStatus.voided")
        case TOrderStatus.Purchased:
            return getText("transactions.orderStatus.captured") // We only show captured for purchased orders to avoid confusing the merchant
    }
}

export const resolveWinbackText = (po: Order) => {
    const platformOrderId = po.winbackOriginalOrderId.replace(/^0+/, "")
    return getText("transactions.winback.originalOrderId", { platformOrderId: platformOrderId })
}

export const getTransactionTypeTranslation = (type: TransactionType) => {
    switch (type) {
        case TransactionType.Auth:
            return getText("transactions.statuses.authorization")
        case TransactionType.Capture:
            return getText("transactions.statuses.capture")
        case TransactionType.Refund:
            return getText("transactions.statuses.refund")
        case TransactionType.Void:
            return getText("transactions.statuses.void")
        case TransactionType.Purchase:
            return getText("transactions.statuses.capture")
    }
}

export const getTransactionStatusTranslation = (status: TransactionStatus) => {
    switch (status) {
        case TransactionStatus.Cancelled:
            return getText("transactions.statuses.cancelled")
        case TransactionStatus.Failure:
            return getText("transactions.statuses.failed")
        case TransactionStatus.Pending:
            return getText("transactions.statuses.pending")
        case TransactionStatus.Success:
            return getText("transactions.statuses.successful")
    }
}

export const getOrderLink = (selectedWebshopId: string, orderId: string, relationId: string, withResource?: boolean) => {
    const domainWithScheme = `${window.location.protocol}//${window.location.host}/`

    return `${
        withResource ? domainWithScheme : ""
    }orders?webshopId=${selectedWebshopId}&orderId=${orderId}&relationId=${relationId}`
}

export const getActionReportData = (order: Order, webshopId: string, swMerchantId: string): IReportOrderActionData => {
    return {
        webshopId: webshopId,
        platformOrderId: getPlatformOrderIdFormatted(order.platformOrderId),
        swOrderPageUrl: getOrderLink(webshopId, order.orderId, swMerchantId || "", true),
        relationId: swMerchantId,
        userRelationType: "Merchant",
    }
}

export function replaceOrderInList(orders: Order[], order: Order) {
    const index = orders.findIndex((o) => o.orderId === order.orderId)
    if (index < 0) {
        return orders
    }
    return [...orders.slice(0, index), order, ...(index + 1 === orders.length ? [] : orders.slice(index + 1, orders.length))]
}

export function showPleaseSelectActionErrorModal(dispatch: ReduxDispatch) {
    dispatch(
        addModalThunk({
            type: "error",
            title: getText("transactions.bulkActionModals.titles.bulkActions"),
            errorMessage: getText("transactions.bulkActionModals.errors.selectAction"),
        })
    )
}

export function showPleaseSelectOrdersErrorModal(dispatch: ReduxDispatch, actionName: string) {
    const actionTitle = getText(`transactions.${actionName}Modal.title`)

    dispatch(
        addModalThunk({
            type: "error",
            title: actionTitle,
            errorMessage: getText(`transactions.bulkActionModals.errors.${actionName}SelectOrders`),
        })
    )
}

export async function getBulkCancelModalAnswer(dispatch: ReduxDispatch, selectedOrders: Order[]) {
    return await dispatch(
        addModalThunk({
            type: "cancelTransaction",
            selectedOrders: selectedOrders,
        })
    )
}

export async function getBulkCaptureRefundModalAnswer(dispatch: ReduxDispatch, selectedOrders: Order[], selectedValue: string) {
    return await dispatch(
        addModalThunk({
            type: "confirmCaptureRefund",
            selectedOrders: selectedOrders,
            isRefund: selectedValue === "refund",
        })
    )
}
