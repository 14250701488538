import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getText, requestThunk } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { StoreState } from "../StoreState"
import { getGatewaysForMerchantThunk } from "./gatewayThunks"
import { getOnboardingStatusThunk } from "./swiipePaymentsThunks"
import { sendServiceConfirmationEmailThunk } from "./swiipeServicesThunks"

export const viabillDisableOnboardingThunk =
    (swMerchantId: string, webshopId: string, gatewayId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Payments.patchGateway(gatewayId), {
                data: {
                    webshopId,
                    paymentType: "Viabill",
                    enabled: false,
                },
            })
        )

        await dispatch(getGatewaysForMerchantThunk(swMerchantId, true))
        await dispatch(getOnboardingStatusThunk(swMerchantId, webshopId, true, ["Viabill"]))
    }

export interface IViabillGetOnboardingDataResponse {
    apiKey: string
}

export const getViabillOnboardingDataThunk =
    (webshopId: string): ThunkAction<Promise<IViabillGetOnboardingDataResponse>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        return await dispatch(
            requestThunk<IViabillGetOnboardingDataResponse>(endpoints.Viabill.getOnboardingData, {
                params: {
                    webshopId: webshopId,
                },
            })
        )
    }

export interface IViabillOnboardingRegistrationData {
    swMerchantId: string
    webshopId: string
    email: string
    companyName: string
    address: string
    contactName: string
    contactPhone: {
        countryCode: string
        number: string
    }
    postalCode: string
    city: string
    country: string
    locale: string
    cvrNumber: string
}

export const viabillOnboardingRegistrationThunk =
    (data: IViabillOnboardingRegistrationData): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Viabill.onboardByRegistration, {
                data: data,
            })
        )

        await dispatch(
            sendServiceConfirmationEmailThunk(data.swMerchantId, "ViabillConfirmation", getText("serviceNames.viabill"))
        )

        await dispatch(getGatewaysForMerchantThunk(data.swMerchantId, true))
        await dispatch(getOnboardingStatusThunk(data.swMerchantId, data.webshopId, true, ["Viabill"]))
    }

export interface IViabillOnboardingLoginData {
    swMerchantId: string
    webshopId: string
    viabillApiKey: string
}

export const viabillOnboardingLoginThunk =
    (data: IViabillOnboardingLoginData): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk<void>(endpoints.Viabill.onboardByLogin, {
                data: data,
            })
        )

        await dispatch(
            sendServiceConfirmationEmailThunk(data.swMerchantId, "ViabillConfirmation", getText("serviceNames.viabill"))
        )

        await dispatch(getGatewaysForMerchantThunk(data.swMerchantId, true))
        await dispatch(getOnboardingStatusThunk(data.swMerchantId, data.webshopId, true, ["Viabill"]))
    }
