import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { getText, requestThunk, ServerError, sharedConfigurationSelectors } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { StoreState } from "../StoreState"
import { getGatewaysForMerchantThunk } from "./gatewayThunks"
import { addModalThunk } from "./modalThunks"
import { getOnboardingStatusThunk } from "./swiipePaymentsThunks"
import { sendServiceConfirmationEmailThunk } from "./swiipeServicesThunks"

export const anydayDisableOnboardingThunk =
    (swMerchantId: string, webshopId: string, gatewayId: string): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        await dispatch(
            requestThunk(endpoints.Payments.patchGateway(gatewayId), {
                data: {
                    webshopId,
                    paymentType: "Anyday",
                    enabled: false,
                },
            })
        )

        await dispatch(getGatewaysForMerchantThunk(swMerchantId, true))
        await dispatch(getOnboardingStatusThunk(swMerchantId, webshopId, true, ["Anyday"]))
    }

export interface IAnydayGetOnboardingDataResponse {
    apiKey: string
    privateKey: string
}

export const getAnydayOnboardingDataThunk =
    (webshopId: string): ThunkAction<Promise<IAnydayGetOnboardingDataResponse>, StoreState, null, AnyAction> =>
    async (dispatch) => {
        return await dispatch(
            requestThunk<IAnydayGetOnboardingDataResponse>(endpoints.Anyday.getOnboardingData, {
                params: {
                    webshopId: webshopId,
                },
            })
        )
    }

export interface IAnydayOnboardingConnectData {
    swMerchantId: string
    webshopId: string
    anydayApiKey: string
    anydayPrivateKey: string
}

export const anydayOnboardingConnectThunk =
    (data: IAnydayOnboardingConnectData): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const env = sharedConfigurationSelectors.environment(getState())
        await dispatch(
            requestThunk(
                endpoints.Anyday.onboardConnect,
                {
                    data: data,
                },
                {
                    errorHandlers: [
                        {
                            errorType: "ApiKeyInvalidError",
                            handleError: async (error: ServerError) => {
                                await dispatch(
                                    addModalThunk({
                                        type: "error",
                                        errorMessage: getText("anydayOnboarding.apiKeyInvalid"),
                                    })
                                )
                                return true
                            },
                        },
                        {
                            errorType: "ApiKeyEnvironmentError",
                            handleError: async (error: ServerError) => {
                                await dispatch(
                                    addModalThunk({
                                        type: "error",
                                        errorMessage:
                                            env === "Production"
                                                ? getText("anydayOnboarding.apiKeyTestNotAllowed")
                                                : getText("anydayOnboarding.apiKeyProdNotAllowed"),
                                    })
                                )
                                return true
                            },
                        },
                    ],
                }
            )
        )

        await dispatch(sendServiceConfirmationEmailThunk(data.swMerchantId, "AnydayConfirmation", getText("serviceNames.anyday")))

        await dispatch(getGatewaysForMerchantThunk(data.swMerchantId, true))
        await dispatch(getOnboardingStatusThunk(data.swMerchantId, data.webshopId, true, ["Anyday"]))
    }
