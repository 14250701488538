import cn from "classnames"
import React from "react"
import { PageContainer, PageWrapper } from "swiipe.portal.shared"
import SwiipeAcquiringLogoImage from "../../../src/images/swiipe-logo.png"
import FormHeaderLogoContainer, { IFormHeaderLogo } from "../form/title/FormHeaderLogoContainer"

export interface IServicesOnboardingPageWrapper {
    children?: React.ReactNode
    hideContent?: boolean
    containerClass?: string
    customLogo?: string
    customLogoWidth?: string
    customLogoClass?: string
    additionalLogos?: IFormHeaderLogo[]
    isWide?: boolean
}

export const ServicesOnboardingPageWrapper = ({
    children,
    hideContent,
    containerClass,
    customLogo,
    customLogoWidth,
    customLogoClass,
    additionalLogos,
    isWide,
}: IServicesOnboardingPageWrapper) => {
    return (
        <PageWrapper hideContent={hideContent}>
            <PageContainer id="sw-services-onboarding-page" classNameBody={containerClass} transparent>
                <div className={cn("main-container", { "main-container--wide": isWide })}>
                    <FormHeaderLogoContainer
                        mainLogo={{
                            src: customLogo ? customLogo : SwiipeAcquiringLogoImage,
                            className: customLogoClass,
                            alt: "swiipe-acquiring-logo",
                            width: customLogoWidth ? customLogoWidth : "140px",
                        }}
                        additionalLogos={additionalLogos}
                    />
                    {children}
                </div>
            </PageContainer>
        </PageWrapper>
    )
}

export default ServicesOnboardingPageWrapper
