import { AnyAction } from "redux"
import { ThunkAction } from "redux-thunk"
import { requestThunk, userSelectors } from "swiipe.portal.shared"
import { endpoints } from "../../data/endpoints"
import { getMerchantContact } from "../../services/merchantService"
import { StoreState } from "../StoreState"
import { merchantSelectors } from "../reducers/merchantReducer"
import { partnerSelectors } from "../reducers/partnerReducer"
import { userRelationSelectors } from "../reducers/userRelationReducer"
import { ensurePartnerDataThunk } from "./partnerThunks"

export type TServiceEmailNotification =
    //Confirmation emails
    | "PspBasicConfirmation"
    | "PspBusinessConfirmation"
    | "DankortConfirmation"
    | "SwishConfirmation"
    | "ForbrugsforeningenConfirmation"
    | "ViabillConfirmation"
    | "AnydayConfirmation"
    | "MobilePayOnlineConfirmation"
    | "AccountToAccountConfirmation"
    | "WinbackConfirmation"
    | "PlusSellConfirmation"
    | "ReturnSellConfirmation"
    | "VippsConfirmation"
    | "ApplePayConfirmation"
    | "KlarnaConfirmation"
    | "ResursConfirmation"
    | "AnywhereMobileConfirmation"
    //Cancellation emails
    | "ViabillCancellationEmail"
    | "AnydayCancellationEmail"
    | "KlarnaCancellationEmail"
    | "PspCancellationEmail"
    | "GeneralServiceCancellationEopEmail" // EOP = end of period
    | "GeneralCardPaymentsCancellationEmail"
    | "GeneralServiceCancellationInstantEmail"

interface IUserContactDataForEmail {
    contactName: string
    contactEmail: string

    isPartner: boolean
    partnerId?: string
    partnerName?: string
}

interface ISendServiceNotificationEmailRequestData extends IUserContactDataForEmail {
    serviceNotificationType: TServiceEmailNotification
    toEmail: string
    swMerchantId: string

    sendingActorRelationType: string
    sendingActorRelationId: string

    // Email specific props
    serviceName?: string
    shouldCancelAtProvider?: boolean
}

export const sendServiceCancellationEmailThunk =
    (
        swMerchantId: string,
        serviceNotificationType: TServiceEmailNotification,
        serviceName: string,
        shouldCancelAtProvider?: boolean
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const contactDataForEmail = await dispatch(getUserContactDataForEmail(swMerchantId))
        const currentRelation = userRelationSelectors.currentUserRelation(getState())

        if (!contactDataForEmail || !currentRelation) {
            return
        }

        const requestData: ISendServiceNotificationEmailRequestData = {
            serviceNotificationType: serviceNotificationType,
            toEmail: contactDataForEmail.contactEmail,
            swMerchantId: swMerchantId,

            sendingActorRelationType: currentRelation.relationType,
            sendingActorRelationId: currentRelation.id,

            ...contactDataForEmail,

            serviceName: serviceName,
            shouldCancelAtProvider: shouldCancelAtProvider ?? false,
        }

        await dispatch(
            requestThunk(endpoints.Identity.sendServiceNotificationEmail, {
                data: requestData,
            })
        )
    }

export const sendServiceConfirmationEmailThunk =
    (
        swMerchantId: string,
        serviceNotificationType: TServiceEmailNotification,
        serviceName: string
    ): ThunkAction<Promise<void>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const contactDataForEmail = await dispatch(getUserContactDataForEmail(swMerchantId))
        const currentRelation = userRelationSelectors.currentUserRelation(getState())

        if (!contactDataForEmail || !currentRelation) {
            return
        }

        const requestData: ISendServiceNotificationEmailRequestData = {
            serviceNotificationType: serviceNotificationType,
            toEmail: contactDataForEmail.contactEmail,
            swMerchantId: swMerchantId,

            sendingActorRelationType: currentRelation.relationType,
            sendingActorRelationId: currentRelation.id,

            ...contactDataForEmail,

            serviceName: serviceName,
        }

        await dispatch(
            requestThunk(endpoints.Identity.sendServiceNotificationEmail, {
                data: requestData,
            })
        )
    }

const getUserContactDataForEmail =
    (swMerchantId: string): ThunkAction<Promise<IUserContactDataForEmail | undefined>, StoreState, null, AnyAction> =>
    async (dispatch, getState) => {
        const contactData: IUserContactDataForEmail = {
            contactName: "",
            contactEmail: "",

            isPartner: false,
        }

        const userRelations = userRelationSelectors.userRelations(getState())
        const partnerRelation = userRelations?.find(
            (r) => !r.isMasterPartner && r.subRelations?.find((sr) => sr.id === swMerchantId)
        )

        if (partnerRelation) {
            const ownerPartnerId = partnerRelation.id
            await dispatch(ensurePartnerDataThunk(true, ownerPartnerId))
            const partnerDetails = partnerSelectors.partnerDetailsById(getState(), ownerPartnerId)
            const userData = userSelectors.userData(getState())

            if (!partnerDetails || !userData) {
                return
            }

            contactData.contactName = partnerDetails.contactFullName || userData.user.fullName || partnerDetails.name
            contactData.contactEmail = partnerDetails.contactEmail ? partnerDetails.contactEmail : userData.user.email!

            contactData.isPartner = true
            contactData.partnerId = ownerPartnerId
            contactData.partnerName = partnerDetails.name
        } else {
            const merchantDetails = merchantSelectors.merchantDetails(getState())
            const userData = userSelectors.userData(getState())

            if (!merchantDetails || !userData) {
                return
            }

            const swMerchantContact = getMerchantContact(merchantDetails)

            contactData.contactName = swMerchantContact?.name || userData.user.fullName || merchantDetails.swMerchant.name
            contactData.contactEmail = swMerchantContact?.email ? swMerchantContact.email : userData.user.email!
        }

        return contactData
    }
