import { route } from "navi"
import React from "react"
import ApplePayOnboardingPage from "../pages/applepay/ApplePayOnboardingPage"
import DankortOnboardingPage from "../pages/dankort/DankortOnboardingPage"
import ForbrugsforeningenOnboardingPage from "../pages/forbrugsforeningen/ForbrugsforeningenOnboardingPage"
import KlarnaOnboardingPage from "../pages/klarna/KlarnaOnboardingPage"
import ClearhausDetailsPage from "../pages/merchant/ClearhausDetailsPage"
import CreatePaymentLinkPage from "../pages/merchant/CreatePaymentLinkPage"
import MobilepayOnlineOnboardingPage from "../pages/mobilepay/MobilepayOnlineOnboardingPage"
import ResursOnboardingPage from "../pages/resurs/ResursOnboardingPage"
import AddPaymentMethodPage from "../pages/swiipepayments/AddPaymentMethodPage"
import ClearHausDownloadOnboardingDataPage from "../pages/swiipepayments/DownloadOnboardingDataPage"
import SwiipeServicesOnboardingPage from "../pages/swiipepayments/SwiipeServicesOnboardingPage"
import SwitchServicesPage from "../pages/swiipepayments/SwitchServicesPage"
import WinbackOnboardingPage from "../pages/swiipepayments/WinbackOnboardingPage"
import AnydayOnboardingPage from "../pages/swiipepayments/anydayOnboarding/AnydayOnboardingPage"
import { AnywhereMobileOnboardingPage } from "../pages/swiipepayments/anywhereMobile/AnywhereMobileOnboardingPage"
import ClearhausEditPage from "../pages/swiipepayments/clearhausOnboarding/ClearhausEditPage"
import ClearhausOnboardingPage from "../pages/swiipepayments/clearhausOnboarding/ClearhausOnboardingPage"
import { ClearhausUpdateInfoPage } from "../pages/swiipepayments/clearhausOnboarding/ClearhausUpdateInfoPage"
import PlusSellOnboardingPage from "../pages/swiipepayments/plusSell/PlusSellOnboardingPage"
import ReturnSellOnboardingPage from "../pages/swiipepayments/returnSell/ReturnSellOnboardingPage"
import ViabillOnboardingPage from "../pages/swiipepayments/viabillOnboarding/ViabillOnboardingPage"
import SwishOnboardingPage from "../pages/swish/SwishOnboardingPage"
import { navigationService } from "../services/navigationService"
import { disableForEnvSandbox, getRouteIncludingEnv } from "../services/routesService"

const swiipePaymentsRoutes = {
    "/dankort_onboarding_single": route({
        view: (
            <DankortOnboardingPage
                webshopIds={[]}
                onGoBack={() => {
                    navigationService.navigate("/plugins")
                }}
                onOnboarded={() => {}}
            />
        ),
    }),
    "/download_onboarding": route({
        view: <ClearHausDownloadOnboardingDataPage />,
    }),
    "/switchservices": route({
        view: <SwitchServicesPage />,
    }),
    "/add_payment_method": route({
        view: <AddPaymentMethodPage />,
    }),
    "/swiipepayments_onboard": route({
        view: <SwiipeServicesOnboardingPage onboardingType={"cards"} />,
    }),
    "/dankort_onboard": route({
        view: <SwiipeServicesOnboardingPage onboardingType={"dankort"} />,
    }),
    "/forbrugsforeningen_onboard": route({
        view: <SwiipeServicesOnboardingPage onboardingType={"forbrugsforeningen"} />,
    }),
    "/viabill_onboard": route({
        view: <SwiipeServicesOnboardingPage onboardingType={"viabill"} />,
    }),
    "/viabill_edit": route({
        view: <ViabillOnboardingPage onGoBack={() => navigationService.navigate("/plugins")} onOnboarded={() => {}} isEdit />,
    }),
    "/anyday_onboard": route({
        view: <SwiipeServicesOnboardingPage onboardingType={"anyday"} />,
    }),
    "/anyday_edit": route({
        view: <AnydayOnboardingPage onGoBack={() => navigationService.navigate("/plugins")} onOnboarded={() => {}} isEdit />,
    }),
    "/winback_onboard": getRouteIncludingEnv(<SwiipeServicesOnboardingPage onboardingType="winback" />, disableForEnvSandbox),
    "/analytics_onboard": route({
        view: <SwiipeServicesOnboardingPage onboardingType="externalAnalytics" />,
    }),
    "/winback_edit": getRouteIncludingEnv(
        <WinbackOnboardingPage
            webshopIds={[]}
            isEditMode
            onOnboarded={() => {
                navigationService.navigate("/plugins")
            }}
            onGoBack={() => {
                navigationService.navigate("/plugins")
            }}
        />,
        disableForEnvSandbox
    ),
    "/plussell_onboard": route({
        view: <SwiipeServicesOnboardingPage onboardingType="plusSell" />,
    }),
    "/plussell_details": route({
        view: (
            <PlusSellOnboardingPage
                isEditMode
                webshopIds={[]}
                onGoBack={() => navigationService.navigate("/plugins")}
                onOnboarded={() => {}}
            />
        ),
    }),
    "/anywhere_mobile_onboard": route({
        view: <SwiipeServicesOnboardingPage onboardingType="anywhereMobile" />,
    }),
    "/anywhere_mobile_details": route({
        view: (
            <AnywhereMobileOnboardingPage
                isEdit
                webshopIds={[]}
                onGoBack={() => navigationService.navigate("/plugins")}
                onOnboarded={() => {}}
            />
        ),
    }),
    "/returnsell_onboard": route({
        view: <SwiipeServicesOnboardingPage onboardingType="returnSell" />,
    }),
    "/returnsell_details": route({
        view: (
            <ReturnSellOnboardingPage
                isEditMode
                webshopIds={[]}
                onGoBack={() => navigationService.navigate("/plugins")}
                onOnboarded={() => {}}
            />
        ),
    }),
    "/dankort_details": route({
        view: (
            <DankortOnboardingPage
                isEdit
                onOnboarded={() => {
                    navigationService.navigate("/plugins")
                }}
                onGoBack={() => {
                    navigationService.navigate("/plugins")
                }}
            />
        ),
    }),
    "/forbrugsforeningen_details": route({
        view: (
            <ForbrugsforeningenOnboardingPage
                isEdit
                onOnboarded={() => navigationService.navigate("/plugins")}
                onGoBack={() => navigationService.navigate("/plugins")}
            />
        ),
    }),
    "/mpo_details": route({
        view: (
            <MobilepayOnlineOnboardingPage
                isEdit
                onOnboarded={() => {
                    navigationService.navigate("/plugins")
                }}
                onGoBack={() => {
                    navigationService.navigate("/plugins")
                }}
            />
        ),
    }),
    "/swish_onboarding": route({
        view: <SwiipeServicesOnboardingPage onboardingType={"swish"} />,
    }),
    "/swish_details": route({
        view: (
            <SwishOnboardingPage
                isEdit
                onOnboarded={() => {
                    navigationService.navigate("/plugins")
                }}
                onGoBack={() => {
                    navigationService.navigate("/plugins")
                }}
            />
        ),
    }),
    "/clearhaus_details": route({
        view: <ClearhausDetailsPage />,
    }),
    // "/vipps_onboarding": route({
    //     view: <SwiipeServicesOnboardingPage onboardingType={"vipps"} />,
    // }),
    // "/vipps_details": route({
    //     view: (
    //         <VippsOnboardingPage
    //             isEdit
    //             onOnboarded={() => {
    //                 navigationService.navigate("/plugins")
    //             }}
    //             onGoBack={() => {
    //                 navigationService.navigate("/plugins")
    //             }}
    //         />
    //     ),
    // }),
    "/applepay_onboarding": route({
        view: <SwiipeServicesOnboardingPage onboardingType={"applepay"} />,
    }),
    "/applepay_details": route({
        view: (
            <ApplePayOnboardingPage
                isEdit
                onOnboarded={() => {
                    navigationService.navigate("/plugins")
                }}
                onGoBack={async () => {
                    navigationService.navigate("/plugins")
                }}
            />
        ),
    }),
    "/klarna_onboarding": route({
        view: <SwiipeServicesOnboardingPage onboardingType={"klarna"} />,
    }),
    "/klarna_details": route({
        view: (
            <KlarnaOnboardingPage
                isEdit
                onOnboarded={() => {
                    navigationService.navigate("/plugins")
                }}
                onGoBack={() => {
                    navigationService.navigate("/plugins")
                }}
            />
        ),
    }),
    "/resurs_onboarding": route({
        view: <SwiipeServicesOnboardingPage onboardingType={"resurs"} />,
    }),
    "/resurs_details": route({
        view: (
            <ResursOnboardingPage
                isEdit
                onGoBack={() => {
                    navigationService.navigate("/plugins")
                }}
            />
        ),
    }),
    "/clearhaus_edit": route({
        view: <ClearhausEditPage />,
    }),
    "/clearhaus_update_info": route({
        view: <ClearhausUpdateInfoPage />,
    }),
    // "/account_to_account_onboarding": route({
    //     view: <SwiipeServicesOnboardingPage onboardingType={"accountToAccount"} />,
    // }),
    // "/account_to_account_details": route({
    //     view: (
    //         <A2aOnboardingPage
    //             isEdit
    //             onGoBack={() => {
    //                 navigationService.navigate("/plugins")
    //             }}
    //         />
    //     ),
    // }),
    "/view_clearhaus_application": route({
        view: (
            <ClearhausOnboardingPage
                withWebshopIds={[]}
                onGoBack={() => navigationService.navigate("/plugins")}
                onOnboarded={() => {}}
                viewMode
            />
        ),
    }),
    "/create_payment_link": route({
        view: <CreatePaymentLinkPage />,
    }),
}
export default swiipePaymentsRoutes
